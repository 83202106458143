import React from 'react'
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import '../../CSS/Navigation.css'
import API from './Global_API'


export default class Navigation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loginUser: '',
            userName: ''
        }
        if (localStorage.getItem('IqaloginUser')) {
            this.state.loginUser = JSON.parse(localStorage.getItem('IqaloginUser'));
            // this.state.userName = this.state.loginUser.name

            let learner_group = JSON.stringify(
                {
                    iqa_id: this.state.loginUser.userId,
                });
    
            fetch(API + 'display_profile.php',
                {
                    method: 'POST',
                    body: learner_group
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    if (responseJsonFromServer.result === 'success') {
                        this.setState({userName:responseJsonFromServer.iqa_data[0].first_name +' '+responseJsonFromServer.iqa_data[0].last_name})
                    }else{
                        console.log("display_profile.php",responseJsonFromServer);
                    }
                }).catch((error) => {
                    console.log(error.message);
                });
        }
        
    }

    componentWillMount() {
        let loginUser = localStorage.getItem("IqaloginUser")
        if (loginUser == null) {
            window.location.href = '/';
        }
    }

    render() {
        return (
            <Navbar bg="#223042" expand="lg">
                <Navbar.Brand href="/dashboard" style={{ paddingLeft: 15 }}><img src={require('../../assets/pta-logo2.png')} alt="PTA CMS" style={{ width: 55, height: 55, position: 'absolute', top: 15 }} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto nav" >
                        <Nav.Link href="/dashboard">Dashboard</Nav.Link>
                        <Nav.Link href="/messages">Messages</Nav.Link>
                        <Nav.Link href="/IQACalendar">Calendar</Nav.Link>
                    </Nav>
                    <Nav>
                        <NavDropdown title={this.state.userName} id="basic-nav-dropdown" style={{ paddingRight: 50 }}>
                            <NavDropdown.Item href="/profile" >My profile</NavDropdown.Item>
                            <NavDropdown.Item href="/Logout" >Sign out</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>

                </Navbar.Collapse>
            </Navbar>
        )
    }
}