import React from 'react';
import '../CSS/Login.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import API from './components/Global_API'
import { Icon } from 'react-icons-kit'
import { eye, withLine } from 'react-icons-kit/entypo'

class LoginScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            current_date: '',
            showHidePassword: "password"
        }
    }

    componentDidMount() {
        var that = this;

        var date = new Date().getDate(); //Current Date
        var month = new Date().getMonth() + 1; //Current Month
        var year = new Date().getFullYear(); //Current Year
        var hours = new Date().getHours(); //Current Hours
        var min = new Date().getMinutes(); //Current Minutes
        var sec = new Date().getSeconds(); //Current Seconds

        that.setState({
            current_date:
                year + '-' + month + '-' + date + ' ' + hours + ':' + min + ':' + sec,
        });
    }

    handleChangeEmail = (e) => {
        let pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (e.target.value.trim().length === 0) {
            document.getElementsByClassName('error_email')[0].innerHTML = 'Email is required.';
            document.getElementsByClassName('error_email')[0].style.display = 'block';
            document.getElementById('email').focus();
            e.target.classList.add('error');
        } else if (e.target.value.trim().length < 5 || e.target.value.trim().length > 50) {
            document.getElementsByClassName('error_email')[0].innerHTML = 'Email must be between 5 to 50 character.';
            document.getElementsByClassName('error_email')[0].style.display = 'block';
            document.getElementById('email').focus();
            e.target.classList.add('error');
        } else if (!e.target.value.trim().match(pattern)) {
            document.getElementsByClassName('error_email')[0].innerHTML = 'Please Enter valid Email ID.';
            document.getElementsByClassName('error_email')[0].style.display = 'block';
            document.getElementById('email').focus();
            e.target.classList.add('error');
        }
        else {
            document.getElementsByClassName('error_email')[0].innerHTML = '';
            document.getElementsByClassName('error_email')[0].style.display = 'none';
            e.target.classList.remove('error');
        }
        this.setState({ email: e.target.value });
    }

    handleChangePassword = (e) => {
        if (e.target.value.trim().length === 0) {
            document.getElementsByClassName('error_password')[0].innerHTML = 'Password is required.';
            document.getElementsByClassName('error_password')[0].style.display = 'block';
            document.getElementById('password').focus();
            e.target.classList.add('error');
        } else if (e.target.value.trim().length < 8 || e.target.value.trim().length > 30) {
            document.getElementsByClassName('error_password')[0].innerHTML = 'Password must be between 8 to 30 character.';
            document.getElementsByClassName('error_password')[0].style.display = 'block';
            document.getElementById('password').focus();
            e.target.classList.add('error');
        }
        else {
            document.getElementsByClassName('error_password')[0].innerHTML = '';
            document.getElementsByClassName('error_password')[0].style.display = 'none';
            e.target.classList.remove('error');
        }
        this.setState({ password: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var errorCount = 0;

        if (!this.state.email.trim().match(pattern) || this.state.email.trim().length < 5 || this.state.email.trim().length > 50) {
            errorCount++;
        }
        if (this.state.password.trim().length < 8 || this.state.password.trim().length > 30) {
            errorCount++;
        }
        if (errorCount == 0) {
            let Login = JSON.stringify(
                {
                    email_id: this.state.email,
                    password: this.state.password,
                });
console.log("Login",Login);

            fetch(API + 'iqa_login.php',
                {
                    method: 'POST',

                    body: Login
                }).then((response) => response.json()).then((responseJsonFromServer) => {
                    if (responseJsonFromServer.result === 'success') {
                        localStorage.setItem("IqaloginUser", JSON.stringify({ 'userId': responseJsonFromServer.user_id, 'email': responseJsonFromServer.email_id, 'name': responseJsonFromServer.user_name, 'mobile_number': responseJsonFromServer.mobile_number }))
                        this.props.history.push('/dashboard');
                    } else {
                        let x = document.getElementById("login");
                        x.className = "show";
                        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                        x.innerHTML = responseJsonFromServer.msg;
                    }
                }).catch((error) => {
                    console.log(error.message);
                });
        }
    }

    componentWillMount() {
        let loginUser = localStorage.getItem("IqaloginUser")
        if (loginUser == null) {
            this.props.history.push("/");
        } else {
            this.props.history.push('/dashboard');
        }
    }

    handleforgot() {
        this.props.history.push('/forgotPassword')
    }

    handleShowHide = () => {
        this.setState({
            showHidePassword: this.state.showHidePassword === 'password' ? 'input' : 'password'
        })
    }

    render() {
        return (
            <div className="image-background">
                <div className="container" style={{ paddingTop: '15%' }}>
                    <div className="row " style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottom: 30 }}>
                        {/* <img src={require('../assets/pta-logo2.png')} style={{ width: 80, height: 80 }} /> */}
                    </div>
                    <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: 0 }}>
                        <div className="row col-lg-6 col-xl-5 col-md-8 col-sm-10 divcontainer">
                            <div className="col-lg-6 col-xl-6 col-md-6 col-sm-10 left">
                                {/* <form onClick={this.handleSubmit}> */}
                                <div>
                                    <input type="email" id="email" maxLength={50} className="form-control" autoCorrect="off" autoComplete="off" value={this.state.email} onChange={this.handleChangeEmail} placeholder="Email" required />
                                    <label class="input-error error_email text-left" ></label>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <input type={this.state.showHidePassword} id="password" autoCorrect="off" autoComplete="off" maxLength={30} minLength={8} className="form-control" value={this.state.password} style={{ paddingRight: 35 }} onChange={this.handleChangePassword} placeholder="Password" required />
                                    <Icon icon={this.state.showHidePassword === "password" ? withLine : eye} onClick={this.handleShowHide} size={20} style={{ position: 'relative', top: -30, right: '-40%', cursor: 'pointer', color: 'rgb(82, 81, 81)' }} />
                                    <label class="input-error error_password text-left" style={{ marginTop: -20 }} ></label>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <button onClick={this.handleSubmit} className="btn btn-block" disabled={(this.state.email === '' || this.state.password === '') ? true : false} style={{ backgroundColor: 'rgb(20, 108, 240)', cursor: (this.state.email === '' || this.state.password === '') ? 'no-drop' : 'pointer' }}><text style={{ color: '#fff', fontWeight: 'bold', marginBottom: 0 }}>Sign in</text></button>
                                </div>
                                {/* </form> */}
                            </div>
                            <div className="col-lg-6 col-xl-6 col-md-6 col-sm-10 right" style={{ paddingLeft: 20, paddingRight: 20 }}>
                                <h3 className="text-left">Welcome to PT Academy</h3>
                                <p className="text-left" style={{ fontWeight: "600", lineHeight: 1.4 }}>Sign in to your account for access to your dashboard and courses.</p>
                            </div>
                            <div className="col-12 bottom pt-3">
                                <text onClick={() => this.handleforgot()} style={{ cursor: 'pointer', fontWeight: '600' }} className="text-center">Forgot your password?</text>
                            </div>
                        </div>

                    </div>
                    <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingTop: 20 }}>
                        {/* <div className="col-6 text-center" style={{paddingTop: 20}}> */}
                        {/* <label style={{ textAlign: 'center', color: '#fff', fontWeight: 'bold' }} >New here? Find out more about PTA.</label> */}
                        {/* </div> */}
                        <div id="login"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LoginScreen;
