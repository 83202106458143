import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Icon } from 'react-icons-kit'
import { speech_2, plus } from 'react-icons-kit/ikons'
import { androidSend } from 'react-icons-kit/ionicons';
import Navigation from './components/Navigation';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import API from './components/Global_API'
import { ic_fiber_manual_record } from 'react-icons-kit/md'

const styles = {
    comment_box: {
        border: '1px solid rgb(166, 166, 166)',
        cursor: 'pointer',
        padding: 15,
        borderRadius: 3
    },
    learner: {
        borderBottom: '1px solid #999',
        marginBottom: 15
    },
    addLearner: {
        cursor: 'pointer'
    },
    evidence: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    unitContent: {
        margin: 0,
        backgroundColor: 'rgb(223, 226, 230)'
    },
    inputAssessor_name: {
        border: 'solid',
        borderWidth: 1,
        borderColor: '#999',
        borderTopWidth: 0,
        listStyle: 'none',
        marginTop: 0,
        maxHeight: 150,
        overflowY: 'auto',
        paddingLeft: 0,
        backgroundColor: "#fff"
    },
    inputAssessor_nameList: {
        padding: 10,
        borderBottom: '1px solid rgba(153, 153, 153, 0.32)',
        cursor: 'pointer'
    },
    activeinputAssessor_name: {
        padding: 10,
        borderBottom: '1px solid rgba(153, 153, 153, 0.32)',
        cursor: 'pointer'
    },
}

export default class Sampling_report extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCommentPopup: false,
            showAddLearnerPopup: false,
            assessorReportPopup: false,
            assessor_id: this.props.location.state.learner.accessor_id,
            course_id: this.props.location.state.learner.course_id,
            learner_id: this.props.location.state.learner.id,
            action: this.props.location.state.learner.action,
            iqa_id: '',
            assessor_name: '',
            course_name: '',
            learner_list: [],
            all_learner_list: [],
            learner_result: [],
            feedback_item: '',
            comment: '',
            development_point: '',
            referral_action: '',
            question_data: [],
            view_unit: '',
            view_learner: '',
            showAssessorList: false,
            showLearnerList: false,
            activeAssessor: 0,
            search: '',
            submitValidation: false,
            IQAFeedback: []
        }
        if (localStorage.getItem('IqaloginUser')) {
            this.state.iqa_id = JSON.parse(localStorage.getItem('IqaloginUser')).userId;
        }

    }


    learner_result = () => {
        let action = '';
        if (this.state.action == 'false') {
            action = 'in progress'
        } else {
            action = this.state.action
        }

        let learner = JSON.stringify({
            learner_id: this.state.learner_id,
            iqa_id: this.state.iqa_id,
            course_id: this.state.course_id,
            assessor_id: this.state.assessor_id,
            action: action
        })
        fetch(API + 'individual_learner_report1.php', {
            method: 'POST',
            body: learner
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result == "success") {
                let data = [{ id: responseJsonFromServer.id, learner_name: responseJsonFromServer.learner_name, iqa_report: responseJsonFromServer.iqa_report }];

                this.setState({
                    learner_result: data,
                    assessor_name: responseJsonFromServer.assessor_name,
                    course_name: responseJsonFromServer.iqa_report.course_name,
                    learner_id: ''
                })
                let feedbackData = [{
                    assessor_id: this.state.assessor_id,
                    learner_id: responseJsonFromServer.id,
                    course_id: this.state.course_id,
                    iqa_id: this.state.iqa_id,
                    comment: '',
                    development_points: '',
                    referral_actions: ''
                }]
                this.setState({ IQAFeedback: feedbackData })
                this.handleSubmitValidation();
            }

        }).catch((error) => {
            console.warn(error.message);
        });

    }

    componentDidMount() {
        this.learner_result();
    }


    send_feedback = () => {

        let data = this.state.IQAFeedback

        data.filter((item, i) => {
            if (item.learner_id == this.state.feedback_item.id) {
                item.comment = this.state.comment
                item.development_points = this.state.development_point
                item.referral_actions = this.state.referral_action
            }
        })
        this.setState({IQAFeedback: data})
        
        this.setState({ showCommentPopup: false, comment: '', development_point: '', referral_action: '' })
        // let data = JSON.stringify({
        //     assessor_id: this.state.assessor_id,
        //     learner_id: this.state.feedback_item.id,
        //     course_id: this.state.course_id,
        //     iqa_id: this.state.iqa_id,
        //     comment: this.state.comment,
        //     development_points: this.state.development_point,
        //     referral_actions: this.state.referral_action
        // })

        // fetch(API + 'add_iqa_feedback.php', {
        //     method: 'POST',
        //     body: data
        // }).then((response) => response.json()).then((responseJsonFromServer) => {
        //     if (responseJsonFromServer.result == "success") {
        //         this.setState({ showCommentPopup: false, comment: '', development_point: '', referral_action: '' })
        //     }

        // }).catch((error) => {
        //     console.warn(error.message);
        // });
    }


    handleSubmit = () => {

        let iqa_feedback = JSON.stringify({
            iqa_feedback: this.state.IQAFeedback
        })

        fetch(API + 'add_iqa_feedback.php', {
            method: 'POST',
            body: iqa_feedback
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result == "success") {
                console.log("responseJsonFromServer",responseJsonFromServer);
            }

        }).catch((error) => {
            console.warn(error.message);
        });

        let iqa_result_data = []
        this.state.learner_result.map(item =>
            iqa_result_data.push({
                learner_id: item.id,
                sample_report: [],
                valid: item.iqa_report.iqa_result_data.valid,
                authentic: item.iqa_report.iqa_result_data.authentic,
                reliable: item.iqa_report.iqa_result_data.reliable,
                sufficient: item.iqa_report.iqa_result_data.sufficient,
                all_of_this: item.iqa_report.iqa_result_data.all_of_this,
                action: 'true',
                course_id: this.state.course_id,
                assessor_id: this.state.assessor_id
            })
        )

        this.state.learner_result.map((item, i) => {
            item.iqa_report.iqa_result_data.iqa_report.map(result => {
                iqa_result_data[i].sample_report.push({ unit_id: result.unit_id, view: result.iqaResult == 'true' ? 'true' : result.ass_res_view, result: result.iqaResult })
            })
        })

        let data = JSON.stringify({
            learner_report: iqa_result_data,
            iqa_id: this.state.iqa_id,
        })

        fetch(API + 'submit_iqa_result.php', {
            method: 'POST',
            body: data
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            var x = document.getElementById("login");
            x.className = "show";
            setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
            if (responseJsonFromServer.result == "success") {
                x.innerHTML = responseJsonFromServer.msg;
                setTimeout(() => {
                    this.props.history.push('/dashboard')
                }, 2000);
            } else {
                x.innerHTML = responseJsonFromServer.msg;

            }

        }).catch((error) => {
            console.warn(error.message);
        });

    }





    handleComment_popupShow = (item) => {
        this.setState({ showCommentPopup: true, feedback_item: item })

        let data = JSON.stringify({
            assessor_id: this.state.assessor_id,
            learner_id: item.id,
            course_id: this.state.course_id,
            iqa_id: this.state.iqa_id
        })

        fetch(API + 'display_iqa_feedback.php', {
            method: 'POST',
            body: data
        }).then((response) => response.json()).then((responseJsonFromServer) => {


        }).catch((error) => {
            console.warn(error.message);
        });

    }

    handleComment_popupClose = () => {
        this.setState({ showCommentPopup: false, feedback_item: '', comment: '', development_point: '', referral_action: '' })
    }

    handleAddLearnerPopupopen = () => {
        this.setState({ search: '' })
        let learner = []
        this.state.learner_result.map(item => {
            return learner.push({ learner_id: item.id })
        })
        this.setState({ showAddLearnerPopup: true })

        let data = JSON.stringify({
            accessor_id: this.state.assessor_id,
            course_id: this.state.course_id,
            learner: learner
        })
        console.log("data", data);
        fetch(API + 'learner_list.php', {
            method: 'POST',
            body: data
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result == "success") {
                this.setState({ learner_list: responseJsonFromServer.learner_data, all_learner_list: responseJsonFromServer.learner_data })
            }

        }).catch((error) => {
            console.warn(error.message);
        });
    }

    handleSearch = (e) => {
        this.setState({ search: e.target.value })
        if (e.target.value.length == 0) {
            this.setState({ activeAssessor: -1, showAssessorList: false, assessor_email: '' })
        } else {
            this.setState({ activeAssessor: 0, showAssessorList: true })
        }
        if (e.target.value.length > 0) {
            let AssessorData = this.state.all_learner_list.filter(
                assessor_name => {
                    return (assessor_name.first_name.toLowerCase() + ' ' + assessor_name.middle_name.toLowerCase() + ' ' + assessor_name.last_name.toLowerCase()).includes(e.target.value.toLowerCase());
                }
            );
            this.setState({ learner_list: AssessorData });
            if (AssessorData.length == 0) {
                this.setState({ learner_id: '' })
            }
        }
    }

    addNewLeanerResult = (item) => {
        this.setState({
            learner_id: item.id,
            showAssessorList: false,
            search: item.first_name + ' ' + item.middle_name + ' ' + item.last_name,
            action: item.action
        })
    }

    handleAddLearnerPopupClose = () => {
        this.setState({ showAddLearnerPopup: false })
    }

    handleAddLearner = () => {
        this.setState({ showAddLearnerPopup: false })

        let action = '';
        if (this.state.action == 'false') {
            action = 'in progress'
        } else {
            action = this.state.action
        }

        let learner = JSON.stringify({
            learner_id: this.state.learner_id,
            iqa_id: this.state.iqa_id,
            course_id: this.state.course_id,
            assessor_id: this.state.assessor_id,
            action: action
        })
        fetch(API + 'individual_learner_report1.php', {
            method: 'POST',
            body: learner
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result == "success") {
                let data = this.state.learner_result
                data.push({ id: responseJsonFromServer.id, learner_name: responseJsonFromServer.learner_name, iqa_report: responseJsonFromServer.iqa_report })

                let feedbackData = this.state.IQAFeedback
                feedbackData.push({
                    assessor_id: this.state.assessor_id,
                    learner_id: responseJsonFromServer.id,
                    course_id: this.state.course_id,
                    iqa_id: this.state.iqa_id,
                    comment: '',
                    development_points: '',
                    referral_actions: ''
                })

                this.setState({
                    learner_result: data,
                    learner_id: ''
                }, () => this.report())
                this.handleSubmitValidation();
            }

        }).catch((error) => {
            console.warn(error.message);
        });
    }

    handleAssessorReport_popupOpen = (item, unit) => {
        this.setState({ assessorReportPopup: true, view_unit: unit, view_learner: item })

        let data = JSON.stringify({
            assessor_id: this.state.assessor_id,
            learner_id: item.id,
            unit_id: unit.unit_id,
        })
        console.log("view_assessor_result.php", data);
        fetch(API + 'view_assessor_result.php', {
            method: 'POST',
            body: data
        }).then((response) => response.json()).then((responseJsonFromServer) => {
            if (responseJsonFromServer.result == "success") {
                this.setState({ question_data: responseJsonFromServer.question_data })
            }

        }).catch((error) => {
            console.warn(error.message);
        });
    }

    handleAssessorReport_popupClose = () => {
        this.setState({ assessorReportPopup: false })

        let learner_id = this.state.view_learner.id

        let view_unit = this.state.learner_result
        view_unit.map((learner) => {
            if (learner.id == learner_id) {

                learner.iqa_report.iqa_result_data.iqa_report.map(unit_details => {
                    if (unit_details.unit_id == this.state.view_unit.unit_id) {
                        // if (unit_details.ass_res_view == 'true') {
                        //     unit_details.ass_res_view = 'false'
                        // } else {
                        unit_details.ass_res_view = 'true'
                        // }
                    }
                })
            }
        })
        this.setState({ learner_result: view_unit })
    }

    handleExportPDF = () => {

        const filename = 'ThisIsYourPDFFilename.pdf';

        html2canvas(document.querySelector('#capture')).then(canvas => {
            let pdf = new jsPDF('p', 'mm', 'a4');
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 0, 0, 211, 298);
            pdf.save(filename);
        });
    }

    handleSubmitValidation = () => {
        let iqa_result_data = []
        this.state.learner_result.map(item =>
            iqa_result_data.push({
                learner_id: item.id,
                sample_report: [],
                course_id: this.state.course_id,
                assessor_id: this.state.assessor_id
            })
        )
        this.state.learner_result.map((item, i) => {
            item.iqa_report.iqa_result_data.iqa_report.map(result => {
                iqa_result_data[i].sample_report.push({ unit_id: result.unit_id, view: result.iqaResult == 'true' ? 'true' : result.ass_res_view, result: result.iqaResult })
            })
        })

        const filterByTags = ['true'];
        const filterByTagSet = new Set(filterByTags);

        const result = iqa_result_data.filter((o) =>
            o.sample_report.some((tag) => filterByTagSet.has(tag.view))
        );

        console.log("result", result);
        if (iqa_result_data.length == result.length) {
            this.setState({ submitValidation: true })
        } else {
            this.setState({ submitValidation: false })
        }
    }

    handleAssessorViewResult = (unit, index) => {
        let view_unit = this.state.learner_result
        view_unit.map((learner, i) => {
            if (i == index) {

                learner.iqa_report.iqa_result_data.iqa_report.map(unit_details => {
                    if (unit_details.unit_id == unit.unit_id) {
                        if (unit_details.ass_res_view == 'true') {
                            unit_details.ass_res_view = 'false'
                        } else {
                            unit_details.ass_res_view = 'true'
                        }
                    }
                })
            }
        })
        this.setState({ learner_result: view_unit })

        this.handleSubmitValidation();

    }

    handleIQAResult = (unit, index) => {
        let view_unit = this.state.learner_result
        view_unit.map((learner, i) => {
            if (i == index) {

                learner.iqa_report.iqa_result_data.iqa_report.map(unit_details => {
                    if (unit_details.unit_id == unit.unit_id) {
                        if (unit_details.iqaResult == 'true') {
                            unit_details.iqaResult = 'false'
                        } else {
                            unit_details.iqaResult = 'true'
                        }
                    }
                })
            }
        })
        this.setState({ learner_result: view_unit })

        this.handleSubmitValidation();
    }

    handleVaild = (index, e) => {
        let value = String(e.target.checked);
        let learner_data = this.state.learner_result
        learner_data.map((item, i) => {
            if (i == index) {
                item.iqa_report.iqa_result_data.valid = value
            }
        })
        this.setState({ learner_result: learner_data })
    }

    handleAuthentic = (index, e) => {
        let value = String(e.target.checked);
        let learner_data = this.state.learner_result
        learner_data.map((item, i) => {
            if (i == index) {
                item.iqa_report.iqa_result_data.authentic = value
            }
        })
        this.setState({ learner_result: learner_data })
    }

    handleReliable = (index, e) => {
        let value = String(e.target.checked);
        let learner_data = this.state.learner_result
        learner_data.map((item, i) => {
            if (i == index) {
                item.iqa_report.iqa_result_data.reliable = value
            }
        })
        this.setState({ learner_result: learner_data })
    }

    handleSufficient = (index, e) => {
        let value = String(e.target.checked);
        let learner_data = this.state.learner_result
        learner_data.map((item, i) => {
            if (i == index) {
                item.iqa_report.iqa_result_data.sufficient = value
            }
        })
        this.setState({ learner_result: learner_data })
    }

    handleAll_of_this = (index, e) => {
        let value = String(e.target.checked);
        let learner_data = this.state.learner_result
        learner_data.map((item, i) => {
            if (i == index) {
                item.iqa_report.iqa_result_data.all_of_this = value
            }
        })
        this.setState({ learner_result: learner_data })
    }

    handleComment = (e) => {
        this.setState({ comment: e.target.value })
    }

    handleDevelopmentPoint = (e) => {
        this.setState({ development_point: e.target.value })
    }

    handleReferralAction = (e) => {
        this.setState({ referral_action: e.target.value })
    }

    report() {
        return (
            <div className="row pt-5 pb-5" style={{ display: 'flex', }}>
                {this.state.learner_result.map((item, index) =>
                    <div className="col-lg-4 col-md-4 col-sm-12 left-report-content" key={index}>
                        <h5 style={styles.learner}>{item.learner_name}</h5>
                        <h5>Sample</h5>
                        <div className="row" style={styles.unitContent}>
                            {item.iqa_report.iqa_result_data.iqa_report.map((unit, i) =>
                                unit.complete_status == 'true' ?
                                    <div className="col-4 text-center pb-3" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {unit.unit_name}<br />
                                        <img src={unit.ass_res_view == 'true' ? require('../assets/check.png') : require('../assets/uncheck.png')} style={{ height: 25, width: 25 }} onClick={() => this.handleAssessorViewResult(unit, index)} />
                                        <div style={{ marginTop: 5 }}>
                                            <Button size="sm" onClick={() => this.handleAssessorReport_popupOpen(item, unit)} block>View</Button>
                                        </div>
                                    </div> : ''
                            )}
                        </div>
                        <h5 className="pt-4">Result</h5>
                        <div className="row" style={styles.unitContent}>
                            {item.iqa_report.iqa_result_data.iqa_report.map((unit, i) =>
                                unit.complete_status == 'true' ?
                                    <div className="col-4 text-center pb-3" style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                                        {unit.unit_name}<br />
                                        <img src={unit.iqaResult == 'true' ? require('../assets/check.png') : require('../assets/uncheck.png')} style={{ height: 25, width: 25 }} onClick={() => this.handleIQAResult(unit, index)} />
                                    </div> : ''
                            )}
                        </div>
                        <div className="row" >
                            <div className="col-12 pt-4">
                                <div style={styles.evidence}>
                                    <div className="evidence-field text-center">
                                        Vaild<br />
                                        <input type="checkbox" checked={item.iqa_report.iqa_result_data.valid == 'true' ? true : false} onClick={this.handleVaild.bind(this, index)} style={{ height: 25, width: 25 }} />
                                    </div>
                                    <div className="evidence-field text-center">
                                        Authentic<br />
                                        <input type="checkbox" checked={item.iqa_report.iqa_result_data.authentic == 'true' ? true : false} onClick={this.handleAuthentic.bind(this, index)} style={{ height: 25, width: 25 }} />
                                    </div>
                                    <div className="evidence-field text-center">
                                        Reliable<br />
                                        <input type="checkbox" checked={item.iqa_report.iqa_result_data.reliable == 'true' ? true : false} onClick={this.handleReliable.bind(this, index)} style={{ height: 25, width: 25 }} />
                                    </div>
                                    <div className="evidence-field text-center">
                                        Sufficient<br />
                                        <input type="checkbox" checked={item.iqa_report.iqa_result_data.sufficient == 'true' ? true : false} onClick={this.handleSufficient.bind(this, index)} style={{ height: 25, width: 25 }} />
                                    </div>
                                    <div className="evidence-field text-center">
                                        All of this<br />
                                        <input type="checkbox" checked={item.iqa_report.iqa_result_data.all_of_this == 'true' ? true : false} onClick={this.handleAll_of_this.bind(this, index)} style={{ height: 25, width: 25 }} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12 p-3" onClick={() => this.handleComment_popupShow(item)} >
                                <Button block style={{ fontWeight: 'bold' }}>IQA feedback</Button>
                            </div>
                        </div>
                    </div>


                )}
            </div>
        )
    }


    render() {
        return (
            <div style={{ backgroundColor: '#fff', width: '100%', height: '100vh', overflowY: 'auto', overflowX: 'hidden', }}>
                <Navigation />
                <div id="capture" className="col-12" style={{ paddingTop: 90, paddingRight: 35, paddingLeft: 35 }}>
                    <div className="row">
                        <h6>IQA Sampling Plan</h6>
                    </div>

                    <div className="row" style={{ borderBottom: '1px solid #999' }}>
                        <div className="col-lg-10 col-md-10 col-sm-12 p-0">
                            <h1>{this.state.assessor_name}</h1>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-6 p-0 pb-sm-3">
                            <Button onClick={this.handleExportPDF} block>Export PDF</Button>
                        </div>
                    </div>
                    <div className="row pt-3">
                        <div className="col-11 p-0">
                            <h6>{this.state.course_name}</h6>
                        </div>
                        {/* <div className="col-lg-3 col-ms-3 col-sm-12 p-0">
                            <h6>Centre No. 84</h6>
                        </div> */}
                        <div className="col-1 p-0 pr-3 text-right">
                            <Icon icon={plus} size={30} onClick={this.state.learner_result.length === 3 ? '' : this.handleAddLearnerPopupopen} style={{ padding: 5, color: '#fff', backgroundColor: 'rgb(37, 119, 33)', marginLeft: 10, borderRadius: 30, cursor: this.state.learner_result.length === 3 ? 'no-drop' : 'pointer' }} />
                        </div>
                    </div>

                    {this.report()}

                    <Modal show={this.state.assessorReportPopup} size="lg" onHide={() => { this.setState({ assessorReportPopup: false }) }}>
                        <Modal.Header closeButton style={{ border: 0 }}>
                            {/* <Modal.Title>Modal heading</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body style={{ height: '60vh', overflowY: 'auto' }}>
                            <div>
                                {this.state.question_data.map((item, i) => {
                                    if (item.question_type == "free_text") {
                                        return (
                                            <div style={{ borderBottom: '1px solid #b6aeae94', padding: '10px 0px' }}>
                                                <h5> <Icon icon={ic_fiber_manual_record} />{' ' + item.title}</h5>
                                                <span><span style={{ fontWeight: '600' }}>Answer:</span> {item.learner_answer}</span>
                                            </div>
                                        )
                                    } else if (item.question_type == "question_pool") {
                                        return (
                                            <div style={{ borderBottom: '1px solid #b6aeae94', padding: '10px 0px' }}>
                                                <h5><Icon icon={ic_fiber_manual_record} />{' ' + item.pool_questions_data.pool_question_title}</h5>
                                                <span><span style={{ fontWeight: '600' }}>Answer:</span> {item.learner_answer}</span>
                                            </div>
                                        )
                                    } else if (item.question_type == "ass_multiple_choice") {
                                        return (
                                            <div style={{ borderBottom: '1px solid #b6aeae94', padding: '10px 0px' }}>
                                                <h5><Icon icon={ic_fiber_manual_record} />{' ' + item.title}</h5>
                                                <span><span style={{ fontWeight: '600' }}>Answer:</span> {item.learner_answer}</span>
                                            </div>
                                        )
                                    } else if (item.question_type == "ass_expandable") {
                                        return (
                                            <div>
                                                {item.expandable_options.map((expandable) => (
                                                    <div style={{ borderBottom: '1px solid #b6aeae94', padding: '10px 0px' }}>
                                                        <h5><Icon icon={ic_fiber_manual_record} />{' ' + expandable.title}</h5>
                                                        <span><span style={{ fontWeight: '600' }}>Answer:</span> {expandable.option_answer}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        )
                                    }
                                }
                                )}

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" style={{ width: 200 }} onClick={this.handleAssessorReport_popupClose} >Ok</Button>
                        </Modal.Footer>
                    </Modal>



                    <Modal show={this.state.showCommentPopup} size="lg" onHide={this.handleComment_popupClose}>
                        <Modal.Header closeButton style={{ border: 0 }}>
                            {/* <Modal.Title>Modal heading</Modal.Title> */}
                        </Modal.Header>
                        <Modal.Body style={{ height: '60vh', overflowY: 'auto' }}>
                            <div>
                                <h5>Comment</h5>
                                <textarea rows={5} style={{ width: '100%' }} value={this.state.comment} onChange={this.handleComment}></textarea>
                            </div>
                            <div>
                                <h5>Development points</h5>
                                <textarea rows={5} style={{ width: '100%' }} value={this.state.development_point} onChange={this.handleDevelopmentPoint}></textarea>
                            </div>
                            <div>
                                <h5>Referral actions</h5>
                                <textarea rows={5} style={{ width: '100%' }} value={this.state.referral_action} onChange={this.handleReferralAction}></textarea>
                            </div>



                        </Modal.Body>
                        <Modal.Footer>
                            <div className="col-12" style={{ display: 'flex' }}>
                                <Button
                                    onClick={this.send_feedback}
                                    disabled={this.state.development_point.trim().length == 0 && this.state.comment.trim().length == 0 && this.state.referral_action.trim().length == 0}
                                    style={{ cursor: this.state.development_point.trim().length == 0 && this.state.comment.trim().length == 0 && this.state.referral_action.trim().length == 0 ? 'no-drop' : 'pointer' }}
                                >
                                    Ok
                                </Button>
                            </div>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={this.state.showAddLearnerPopup} size="sm" onHide={this.handleAddLearnerPopupClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add learner</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* <select className="form-control" value={this.state.learner_id} onChange={this.addNewLeanerResult}>
                                <option> select learner</option>
                                {this.state.learner_list.map((item, i) =>
                                    <option key={i} value={item.id}> {item.first_name + ' ' + item.middle_name + ' ' + item.last_name}</option>
                                )}
                            </select> */}
                            <input type="text" placeholder="select learner" autoComplete="off" autoCorrect="off" value={this.state.search} onChange={this.handleSearch} style={styles.inputField} className="form-control" />
                            <div style={{ display: this.state.showAssessorList ? '' : 'none' }}>
                                {this.state.learner_list.length == 0 ? <div style={{ color: 'rgb(123, 123, 123)', padding: '0.5rem' }}>
                                    <em>No user found</em>
                                </div> : <ul style={styles.inputAssessor_name}>
                                        {this.state.learner_list.map((item, index) =>
                                            <li
                                                style={index == this.state.activeAssessor ? styles.activeinputAssessor_name : styles.inputAssessor_nameList}
                                                onClick={() => this.addNewLeanerResult(item)}
                                                id={item.id}
                                            >
                                                {item.first_name + ' ' + item.middle_name + ' ' + item.last_name}
                                            </li>
                                        )}
                                    </ul>}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={this.handleAddLearner} block >Add learner</Button>
                        </Modal.Footer>
                    </Modal>
                    <div className="row pb-5" style={{ justifyContent: 'flex-end', display: 'flex' }}>
                        <div className="col-lg-2 col-ms-3 col-sm-6 col-xs-12 p-0">
                            <Button disabled={!this.state.submitValidation} block onClick={this.handleSubmit} style={{ fontWeight: 'bold' }}>Submit</Button>
                        </div>
                    </div>
                    <div id="login"></div>

                </div>
            </div>
        )
    }
}