import React from 'react';
import Navigation from './components/Navigation';
import { Button, Modal } from 'react-bootstrap';
import ReactCrop from 'react-image-crop'
import API from "./components/Global_API";
import 'react-image-crop/dist/ReactCrop.css';

export default class Profile extends React.Component {
    constructor() {
        super();
        this.state = {
            first_name: '',
            userId: '',
            last_name: '',
            middle_name: '',
            email: '',
            cropImagePopup: false,
            crop_image: null,
            crop: {
                unit: '%',
                width: 30,
                aspect: 1 / 1,
            },
            course_image: '',
            img: '',
            image_error_popup: false,
        }
        if (localStorage.getItem('IqaloginUser')) {
            this.state.userId = JSON.parse(localStorage.getItem('IqaloginUser')).userId;
        }
    }

    componentDidMount() {
        let learner_group = JSON.stringify(
            {
                iqa_id: this.state.userId,
            });

        fetch(API + 'display_profile.php',
            {
                method: 'POST',
                body: learner_group
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                if (responseJsonFromServer.result === 'success') {
                    this.setState({
                        first_name: responseJsonFromServer.iqa_data[0].first_name,
                        last_name: responseJsonFromServer.iqa_data[0].last_name,
                        middle_name: responseJsonFromServer.iqa_data[0].middle_name,
                        email: responseJsonFromServer.iqa_data[0].email,
                        img: API + responseJsonFromServer.iqa_data[0].profile_image
                    })
                }
            }).catch((error) => {
                console.log(error.message);
            });
    }

    press = (e) => {
        if (e.ctrlKey || e.altKey) {
            e.preventDefault();
        } else {
            var key = e.keyCode;
            if (!((key == 8) || (key == 9) || (key == 46) || (key >= 35 && key <= 40) || (key >= 65 && key <= 90))) {
                e.preventDefault();
            }
        }
    }

    handleChangeImage(e) {
        if (e.target.files[0]) {
            var fileName = e.target.files[0].name;
            var idxDot = fileName.lastIndexOf(".") + 1;
            var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
            if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
                if (e.target.files && e.target.files.length > 0) {
                    if (e.target.files && e.target.files.length > 0) {
                        const reader = new FileReader();
                        reader.addEventListener('load', () =>
                            this.setState({ crop_image: reader.result, cropImagePopup: true, image_path: true, saveButtonFlag: true })
                        );
                        reader.readAsDataURL(e.target.files[0]);
                    }
                }
            } else {
                this.setState({ image_error_popup: true })
            }
        }
    }

    handleImageLoaded = (image) => {
        this.imageRef = image;
    }

    handleOnCropChange = (crop) => {
        this.setState({ crop });
    }

    handleOnCropCompleted = (crop, pixelCrop) => {
        this.makeClientCrop(crop);
    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImageUrl = new File([u8arr], filename, { type: mime });
        this.setState({ croppedImageUrl })

    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                this.dataURLtoFile(reader.result, 'cropped.jpg')
            }
        })

        canvas.toBlob(blob => {
            if (!blob) {
                console.error('Canvas is empty');
                return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            this.setState({ set_image_path: this.fileUrl })
        });
    }

    handleImageCrop = (e) => {
        this.setState({ course_image: this.state.croppedImageUrl, cropImagePopup: false, img: this.state.set_image_path })
    }

    addDefaultSrc(e) {
        e.target.src = require('../assets/click_image.png');
        e.target.onerror = null;
    }

    handleFirstName = (e) => {
        if (e.target.value.trim() == "") {
            document.getElementsByClassName('error_first_name')[0].innerHTML = 'First name is required.';
            document.getElementsByClassName('error_first_name')[0].style.display = 'block';
            e.target.classList.add('error');
        } else if (e.target.value.trim().length < 2 || e.target.value.trim().length > 30) {
            
            document.getElementsByClassName('error_first_name')[0].innerHTML = 'First name must be between 2 to 30 character.';
            document.getElementsByClassName('error_first_name')[0].style.display = 'block';
            e.target.classList.add('error');
        } else {
            document.getElementsByClassName('error_first_name')[0].innerHTML = "";
            document.getElementsByClassName('error_first_name')[0].style.display = 'none';
            e.target.classList.remove('error');
            
        }
        this.setState({ first_name: e.target.value })
    }

    handleMiddleName = (e) => {
        this.setState({ middle_name: e.target.value })
    }

    handleLastName = (e) => {
        if (e.target.value.trim() == "") {
            
            document.getElementsByClassName('error_last_name')[0].innerHTML = 'Last name is required.';
            document.getElementsByClassName('error_last_name')[0].style.display = 'block';
            e.target.classList.add('error');
        } else if (e.target.value.trim().length < 2 || e.target.value.trim().length > 30) {
            
            document.getElementsByClassName('error_last_name')[0].innerHTML = 'Last name must be between 2 to 30 character.';
            document.getElementsByClassName('error_last_name')[0].style.display = 'block';
            e.target.classList.add('error');
        } else {
            document.getElementsByClassName('error_last_name')[0].innerHTML = "";
            document.getElementsByClassName('error_last_name')[0].style.display = 'none';
            e.target.classList.remove('error');
        }
        this.setState({ last_name: e.target.value })
    }

    handleSubmit = () => {
        let errorCount = 0;
        if (this.state.first_name.trim().length == 0) {
            errorCount++;
        }
        if (this.state.last_name.trim().length == 0) {
            errorCount++;
        }
        if(errorCount == 0){
        let course = new FormData()
        course.append('iqa_id', this.state.userId);
        course.append('first_name', this.state.first_name);
        course.append('middle_name', this.state.middle_name);
        course.append('last_name', this.state.last_name);
        course.append('profile_image', this.state.course_image);

        fetch(API + 'edit_profile.php',
            {
                method: 'POST',

                body: course
            }).then((response) => response.json()).then((responseJsonFromServer) => {
                var x = document.getElementById("login");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
                if (responseJsonFromServer.result === 'success') {
                    x.innerHTML = responseJsonFromServer.msg;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                } else {
                    x.innerHTML = responseJsonFromServer.msg;
                }
            }).catch((error) => {
                console.log(error.message);
            });
        }
    }

    render() {
        return (
            <div style={{ minHeight: '100vh', overflowX: 'hidden', backgroundColor: 'rgb(238, 242, 246)' }}>
                <Navigation />
                <div>
                    <div className="block-v-md" style={{ backgroundColor: '#ffff', paddingLeft: 20, paddingTop: 60, paddingRight: 20, paddingBottom: 20 }}>
                        <div className="pt-3">
                            <h4>My profile</h4>
                        </div>
                    </div>
                    <div className="body-container" style={{ padding: 10, minHeight: 550 }}>
                        <div className="col-6">
                            <form >
                                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', padding: 5 }}>
                                    <div style={{ width: 200, height: 200, borderRadius: 3 }} >
                                        <div className="col-12">
                                            <input
                                                style={{ display: 'none' }}
                                                ref={fileInput => this.fileInput = fileInput}
                                                className="upload"
                                                width={200}
                                                height={200}
                                                className="mr-3"
                                                onError={this.addDefaultSrc}
                                                src={this.state.img}
                                                alt="Generic placeholder"
                                                onChange={(e) => this.handleChangeImage(e)}
                                                accept="image/jpeg,image/png"
                                                type="file"
                                            />
                                            <img
                                                className="upload"
                                                onError={this.addDefaultSrc}
                                                onClick={() => this.fileInput.click()}
                                                width={200}
                                                height={200}
                                                style={{ borderRadius: 3 }}
                                                className="mr-3"
                                                src={this.state.img}
                                                alt="Generic placeholder"
                                            />

                                            <Modal show={this.state.cropImagePopup} onHide={() => { this.setState({ cropImagePopup: false }) }}>
                                                <ReactCrop
                                                    style={{ margin: 30 }}
                                                    src={this.state.crop_image}
                                                    onError={this.addDefaultSrc}
                                                    onClick={() => this.fileInput.click()}
                                                    className="upload"
                                                    crop={this.state.crop}
                                                    onImageLoaded={this.handleImageLoaded}
                                                    onComplete={this.handleOnCropCompleted}
                                                    onChange={this.handleOnCropChange}
                                                    alt="Generic placeholder"
                                                />
                                                <Button style={{ marginLeft: 30, marginRight: 30, marginBottom: 30, backgroundColor: '#46b4e9', borderColor: '#2fabe6', fontWeight: 'bold' }} onClick={this.handleImageCrop}>Crop</Button>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <label style={{ fontWeight: '600' }}>Email</label>
                                    <input type="text" disabled={true} style={{ cursor: 'not-allowed' }} value={this.state.email} placeholder="Email" class="form-control" />
                                </div>
                                <div className="col-12" style={{ paddingTop: 20 }}>
                                    <label style={{ fontWeight: '600' }}>First name*</label>
                                    <input type="text" placeholder="First name" id="first_name" value={this.state.first_name} maxLength={30} minLength={2} class="form-control" onChange={this.handleFirstName} onKeyDown={this.press} />
                                    <label class="input-error error_first_name text-left"></label>
                                </div>
                                <div className="col-12" style={{ paddingTop: 20 }}>
                                    <label style={{ fontWeight: '600' }}>Middle name</label>
                                    <input type="text" placeholder="Middle name" value={this.state.middle_name} maxLength={30} class="form-control" onChange={this.handleMiddleName} onKeyDown={this.press} />
                                </div>
                                <div className="col-12" style={{ paddingTop: 20 }}>
                                    <label style={{ fontWeight: '600' }}>Last name*</label>
                                    <input type="text" placeholder="Last name" id="last_name" value={this.state.last_name} maxLength={30} minLength={2} class="form-control" onChange={this.handleLastName} onKeyDown={this.press} />
                                    <label class="input-error error_last_name text-left"></label>
                                </div>
                                <div className="col-12" style={{ paddingTop: 20 }}>
                                    <Button onClick={this.handleSubmit} style={{ backgroundColor: '#42cbf5', borderColor: '#42cbf5' }} block>Save</Button>
                                </div>
                            </form>
                            <Modal size="sm" show={this.state.image_error_popup} onHide={() => this.setState({ image_error_popup: false })}>
                                <Modal.Body>Only images are accepted.</Modal.Body>
                                <Modal.Footer>
                                    {/* <Button variant="secondary" onClick={this.handleClose}>No</Button> */}
                                    <Button variant="primary" onClick={() => this.setState({ image_error_popup: false })} block>Ok</Button>
                                </Modal.Footer>
                            </Modal>
                            <div id="login"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}